<template>
  <div class="workOrderDetails">
    <div class="workOrderDetails-content">
      <div class="service-number">
        <div class="item-l">维修单编号：{{ detailParams.serviceNumber }}</div>
        <div class="item-r">{{ detailParams.status | formatStatus }}</div>
      </div>
      <div class="steps">
        <div
          class="steps-content"
          v-if="
            detailParams.status == 0 ||
            detailParams.status == 2 ||
            detailParams.status == 1
          "
        >
          <div class="item-l">1</div>
          <div class="item-r">
            <div class="title">
              <span>受理</span> <span>{{ detailParams.receiveTime }}</span>
            </div>
            <div class="content">
              <p class="label">
                <span>报修人：</span>
                <span>{{ detailParams.applyPeople || "--" }}</span>
              </p>
              <p class="label">
                <span>联系电话：</span>
                <span>{{ detailParams.applyPhone || "--" }}</span>
              </p>
              <p class="label">
                <span>登记人：</span>
                <span>{{ detailParams.registerPeople || "--" }}</span>
              </p>
              <p class="label">
                <span>故障情况描述：</span>
                <span>{{ detailParams.malfunctionDescription || "--" }}</span>
              </p>
            </div>
          </div>
        </div>
        <div
          class="steps-content"
          v-if="detailParams.status == 0 || detailParams.status == 2"
        >
          <div class="item-l">2</div>
          <div class="item-r">
            <div class="title">
              <span>处理</span> <span>{{ detailParams.receiveTime }}</span>
            </div>
            <div class="content">
              <p>
                <span>维修人：</span>
                <span>{{ detailParams.repairName || "" }}</span>
              </p>
              <p>
                <span>专业组别：</span>
                <span>{{ detailParams.majorType || "" }}</span>
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="steps-content" v-if="detailParams.status >= 3">
          <div class="item-l">3</div>
          <div class="item-r">
            <div class="title">
              <span>服务</span> <span>{{ detailParams.repairTime }}</span>
            </div>
            <div class="content">
              <p>
                <span>维修人：</span>
                <span>{{ detailParams.repairName || "--" }}</span>
              </p>
              <p>
                <span>服务工号：</span>
                <span>{{ detailParams.serviceJobNumber || "--" }}</span>
              </p>
              <p>
                <span>服务部门：</span>
                <span>{{ detailParams.serviceDepartmentName || "--" }}</span>
              </p>
              <p>
                <span>维修情况描述：</span>
                <span>{{ detailParams.repairDescription || "--" }}</span>
              </p>
            </div>
          </div>
        </div> -->
        <!-- <div class="steps-content" v-if="detailParams.status >= 4">
          <div class="item-l">4</div>
          <div class="item-r">
            <div class="title">
              <span>反馈</span> <span>{{ detailParams.feedbackTime }}</span>
            </div>
            <div class="content">
              <p>
                <span>反馈部门：</span>
                <span>{{ detailParams.feedbackDepartmentName || "--" }}</span>
              </p>
              <p>
                <span>服务评价：</span>
                <span>{{
                  detailParams.satisfaction | formatSatisfaction
                }}</span>
              </p>
            </div>
          </div>
        </div> -->
        <!-- <div class="steps-content" v-if="detailParams.status == 5">
          <div class="item-l">5</div>
          <div class="item-r">
            <div class="title">
              <span>审核</span>
              <span>{{ detailParams.auditTime || "--" }}</span>
            </div>
            <div class="content">
              <p>
                <span>审核部门：</span>
                <span>{{ detailParams.auditDepartmentName || "--" }}</span>
              </p>
            </div>
          </div>
        </div> -->
        <div class="steps-content finish" v-if="detailParams.status == 2">
          <div class="item-l">3</div>
          <div class="item-r">
            <div class="title">
              <span>完成</span> <span>{{ detailParams.updateTime }}</span>
            </div>
            <div class="content">
              <p>
                <span>维修描述：</span>
                <span>{{ detailParams.repairDescription || "--" }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWorkInfoById, getDeviceWorkInfo } from "./api";
export default {
  name: "workOrderDetails",
  components: {},
  props: {},
  data() {
    return {
      detailParams: {},
    };
  },
  created() {},
  mounted() {
    const { id } = this.$route.query;
    if (id != undefined) {
      this.getWorkInfoById(id);
    }
  },
  filters: {
    formatStatus(value) {
      // 1已受理(待接单)-2已接单(服务中)-3待反馈(已处理)-4已反馈(待审核)-5已完成（审核通过）
      const obj = {
        1: "已受理",
        2: "已接单",
        3: "待反馈",
        4: "已反馈",
        5: "已完成",
      };
      return obj[value];
    },
    formatSatisfaction(value) {
      const obj = {
        1: "非常满意",
        2: "满意",
        3: "一般",
        4: "不满意",
        5: "非常不满意",
      };
      return obj[value];
    },
  },
  methods: {
    //获取工单详情
    getWorkInfoById(id) {
      this.$axios
        .get(getDeviceWorkInfo, {
          params: {
            id,
          },
        })
        .then((res) => {
          if (res.code == 200) {
            this.detailParams = res.data;
          }
        });
    },
  },
};
</script>

<style scoped lang="less">
.workOrderDetails {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 90px 30px 60px 30px;
  background: #f8f8f8;
  .workOrderDetails-content {
    padding: 34px 32px 34px 34px;
    box-sizing: border-box;
    width: 100%;
    background: #ffffff;
    border-radius: 16px;
    .steps {
      margin-top: 50px;
      box-sizing: border-box;
      .steps-content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        &.finish {
          &::before {
            content: none;
          }
        }
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        &::before {
          content: "";
          left: 18px;
          top: 52px;
          position: absolute;
          height: calc(100% - 52px);
          width: 2px;
          // border-left: 5PX dotted #e7e7e7;
          box-sizing: border-box;
          // background-image: repeating-linear-gradient(
          //   to bottom,
          //   #e7e7e7 0,
          //   #e7e7e7 2px,
          //   transparent 4px,
          //   transparent 16px
          // );
          background-image: radial-gradient(
            circle at bottom,
            #e7e7e7 0,
            #e7e7e7 8px,
            transparent 8px,
            transparent 100%
          );
          background-size: 100% 15px;
          background-repeat: repeat-y;
        }
        .item-l {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 24px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ffffff;
          width: 40px;
          height: 40px;
          background: #fe4416;
          border-radius: 50%;
        }
        .item-r {
          width: calc(100% - 68px);
          box-sizing: border-box;
          padding-bottom: 16px;
          .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            span {
              &:first-child {
                font-size: 32px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.85);
                line-height: 44px;
              }
              &:last-child {
                font-size: 24px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.4);
                line-height: 34px;
              }
            }
          }
          .content {
            margin-top: 10px;
            width: 100%;
            background: #fbfafa;
            border-radius: 8px;
            box-sizing: border-box;
            padding: 16px 30px 24px 28px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            p {
              display: flex;
              align-items: center;
              justify-content: space-between;
              &:not(:last-child) {
                margin-bottom: 12px;
              }
              span {
                &:first-child {
                  flex-shrink: 0;
                  font-size: 24px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.85);
                  line-height: 34px;
                }
                &:last-child {
                  max-width: calc(100% - 170px);
                  font-size: 20px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: rgba(0, 0, 0, 0.85);
                  line-height: 28px;
                }
              }
              .photo {
                display: flex;
                flex-wrap: wrap;
                gap: 5px;
                ::v-deep .van-uploader__preview-image {
                  width: 106px;
                  height: 106px;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
    .service-number {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item-l {
        box-sizing: border-box;
        padding: 0 20px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fe4416;
        line-height: 56px;
        width: auto;
        height: 56px;
        background: rgba(254, 68, 22, 0.05);
        border-radius: 6px;
      }
      .item-r {
        flex-shrink: 0;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fe4416;
        line-height: 56px;
      }
    }
  }
}
</style>
